import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components/template-partials/global-components/section"
import InteriorPageHeader from "../components/template-partials/global-components/interior-page-header"
import aboutBG from "../images/1.0_backgroundheader.jpg"

import legalRound from "../images/legal-round.jpg";

const PrivacyPolicyPage = () => {

  return (
    <Layout>
      <SEO title="Neuroblastoma-Info | Accessibility Statement" description="United Therapeutics Oncology is dedicated to ensuring digital accessibility and following the relevant accessibility standards. Learn more." />

      <InteriorPageHeader backgroundImage={aboutBG} roundImage={legalRound} alt="Accessibility">
        <h1 className="section__heading section__heading--green h1">Accessibility</h1>
      </InteriorPageHeader>

      {/* style={{backgroundColor: SassVars.viridian}} */}

      <Section className="interior-page lg_pb-5">
        <div className="row row--inner">
          <div className="columns">
            <div className="row">
              <div className="columns">
              <h2 className="tc-barney h2 jost-med">Accessibility statement for neuroblastoma-info.com</h2>
              <p>United Therapeutics Oncology is committed to ensuring digital accessibility for people with disabilities. We are continually improving the user experience for everyone, and applying the relevant accessibility standards.</p>

              <h3 className="tc-barney h2 jost-med">Conformance status</h3>
              <p>The Web Content Accessibility Guidelines (WCAG) define requirements for designers and developers to improve accessibility for people with disabilities. It defines three levels of conformance: Level A, Level AA, and Level AAA. Neuroblastoma-info.com is partially conformant with WCAG 2.0 level AA. Partially conformant means that some parts of the content do not fully conform to the accessibility standard.</p>
              </div>
            </div>
          </div>
        </div>
      </Section>

    </Layout>
  )
}

export default PrivacyPolicyPage
